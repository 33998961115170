<template>
  <he-card class="preamble">
    <p>Microsoft Support Lifecycle fixed policy provides consistent and predictable guidelines for product support availability at the time of product release. Microsoft will offer a minimum of 10 years support (5 years of Mainstream support and 5 years of Extended support) at the supported service pack level for Business and Developer products. During the Extended Support phase, only security fixes continue to be available. An Extended Hotfix Support Agreement (EHSA) may be subscribed to in order to be able to request non-security related hotfixes.</p>

    <br />
    <p><a href="https://learn.microsoft.com/lifecycle/" target="_blank">Microsoft Support Lifecycle</a></p>

    <br />
    <p><b>Visit these roadmaps to find information about upcoming features and releases:</b></p>
    <p>
      <ul class="bullets">
        <li><a href="https://products.office.com/business/office-365-roadmap" target="_blank">O365</a></li>
        <li><a href="https://azure.microsoft.com/updates?updateType=retirements" target="_blank">Azure</a></li>
        <li><a href="https://roadmap.dynamics.com" target="_blank">Dynamics 365</a></li>
      </ul>
    </p>
    <br />
    <p>Please provide feedback on this view with this short survey: <a href="https://forms.office.com/r/i5DVxUwMVG" target="_blank">link</a></p>
  </he-card>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
  },
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
})
</script>

<style scoped>
.bullets {
  padding-left: 25px;
}
</style>
