<template>
  <div id="page" >
    <script type="module" src="/harmony.js"></script>
    <div v-if="loading">
      <div style="display: flex; align-items: center; justify-content: center;">
        <he-progress-ring indeterminate  />
      </div>
    </div>
    <div v-else>
      <div v-if="error && !authError">
        <Oops icon="error" text="Error" />
      </div>
      <div v-else-if="authError">
        <Oops icon="error" text="Auth error" />
      </div>
      <div v-else>
        <div style="display: flex; align-items: center; gap: var(--he-spacing-x-small)">
          <he-select :value="selectedTemplate" @he-change="onTemplateChange" style="margin-left: auto;">
            <he-option v-for="item in templates" :key="item.name" :value="item.dmclanguageCode" style="padding-left: 5px">{{ item.name }}</he-option>
          </he-select>
          <he-button appearance="primary" @click.prevent="generatePptx" style="margin-right: 5px; color: var(--he-type-on-accent);">Export to PPTX</he-button>
        </div>
        <div class="bucket-container">
          <div class="bucket">
            <harmony-product-list
              title="Expiring <6 months"
              :color="cardColor('--he-color-caution-100')"
              :value="products0to6"
            />
            <harmony-product-list
              title="Entering Extended Support"
              :color="cardColor('--he-color-caution-100')"
              :value="productsExt0to6"
              class="extended-list"
            />
          </div>
          <div class="bucket">
            <harmony-product-list
              title="Expiring 7-12 months"
              :color="cardColor('--he-color-warning-100')"
              :value="products7to12"
            />
            <harmony-product-list
              title="Entering Extended Support"
              :color="cardColor('--he-color-warning-100')"
              :value="productsExt7to12"
              class="extended-list"
            />
          </div>
          <div class="bucket">
            <harmony-product-list
              title="Expiring 13-24 months"
              :color="cardColor('--he-color-neutral-100')"
              :value="products13to24"
            />
            <harmony-product-list
              title="Entering Extended Support"
              :color="cardColor('--he-color-neutral-100')"
              :value="productsExt13to24"
              class="extended-list"
            />
          </div>
          <div class="bucket">
            <harmony-product-list
              title="Expiring 25-36 months"
              :color="cardColor('--he-color-neutral-100')"
              :value="products25to36"
            />
            <harmony-product-list
              title="Entering Extended Support"
              :color="cardColor('--he-color-neutral-100')"
              :value="productsExt25to36"
              class="extended-list"
            />
          </div>
        </div>
        <div class="preamble">
          <Preamble />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import HarmonyProductList from '../components/HarmonyProductList.vue'
import Oops from '../components/Oops.vue'
import Preamble from '../components/Preamble.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('MSX')

export default Vue.extend({
  components: {
    HarmonyProductList,
    Oops,
    Preamble
  },
  data() {
    return {
      toggleNone: null,
      generating: false,
      loadingText: 'Loading...',
      loading: true,
      error: null,
      authError: null,
      selectedTemplate: null
    }
  },
  computed: {
    ...mapGetters(['timeline', 'templates', 'defaultTemplate']),
    products0to6() {
      if (this.timeline) {
        return this.timeline.buckets.filter(x => x.startMonth === 1)[0].productsEndingSupport
      }
      return null
    },
    productsExt0to6() {
      if (this.timeline) {
        return this.timeline.buckets.filter(x => x.startMonth === 1)[0].productsEnteringExtendedSupport
      }
      return null
    },
    products7to12() {
      if (this.timeline) {
        return this.timeline.buckets.filter(x => x.startMonth === 7)[0].productsEndingSupport
      }
      return null
    },
    productsExt7to12() {
      if (this.timeline) {
        return this.timeline.buckets.filter(x => x.startMonth === 7)[0].productsEnteringExtendedSupport
      }
      return null
    },
    products13to24() {
      if (this.timeline) {
        return this.timeline.buckets.filter(x => x.startMonth === 13)[0].productsEndingSupport
      }
      return null
    },
    productsExt13to24() {
      if (this.timeline) {
        return this.timeline.buckets.filter(x => x.startMonth === 13)[0].productsEnteringExtendedSupport
      }
      return null
    },
    products25to36() {
      if (this.timeline) {
        return this.timeline.buckets.filter(x => x.startMonth === 25)[0].productsEndingSupport
      }
      return null
    },
    productsExt25to36() {
      if (this.timeline) {
        return this.timeline.buckets.filter(x => x.startMonth === 25)[0].productsEnteringExtendedSupport
      }
      return null
    }
  },
  methods: {
    ...mapActions({
      loadTimeline: 'loadTimeline',
      loadTemplates: 'loadTemplates',
      exportTimeline: 'exportTimeline'
    }),
    cardColor(variable) {
      var style = getComputedStyle(document.body)
      return style.getPropertyValue(variable)
    },
    onTemplateChange(args) {
      this.selectedTemplate = args.srcElement.value
    },
    async generatePptx() {
      this.loadingText = 'Exporting Timeline...'
      this.generating = true
      try {
        await this.exportTimeline({
          tpid: +this.$route.query.tpid,
          locale: this.selectedTemplate,
          format: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        })
      } catch (err) {
        this.error = err
        this.authError = [401, 403].includes(err.response?.status)
      } finally {
        this.generating = false
        this.loadingText = 'Loading...'
      }
    },
    async load() {
      try {
        this.loading = true
        const locale = window.navigator.userLanguage || window.navigator.language
        await this.loadTemplates(locale)
        this.selectedTemplate = this.defaultTemplate.dmclanguageCode
        const tpid = +this.$route.query.tpid
        await this.loadTimeline({
          tpid,
          locale: this.selectedTemplate
        })
      } catch (err) {
        this.error = err
        this.authError = [401, 403].includes(err.response?.status)
      } finally {
        this.loading = false
      }
    }
  },
  async created() {
    await this.load()
  },
  watch: {
    $route: {
      handler() {
        this.load()
      }
    }
  }
})
</script>

<style scoped>
#page {
  max-width: 1574px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.bucket-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5px;
  flex: 1 1 0px;
}

.bucket {
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
}

.extended-list {
  margin-top: 5px;
}

.download {
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.preamble {
  width: 100%;
  margin-top: 5px;
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

</style>
