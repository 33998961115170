<template>
  <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
    <div style="flex: 1 1 0px; font-size: 2rem; color: var(--he-color-danger-600);">
      <he-icon :name="icon" />
    </div>
    <div style="flex: 1 1 0px;">
      <span>Oops, something went wrong. Click reload and try again.</span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    icon: String
  },
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
})
</script>
