





















import Vue from 'vue'
import IProductBucket from '../models/IProductBucket'

export default Vue.extend({
  props: {
    value: Array as () => IProductBucket[],
    title: String,
    color: String,
    loading: Boolean
  },
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    timeline() {
      if (this.value) {
        return this.value
      }
      return Array as () => IProductBucket[]
    }
  },
  methods: {
  }
})
